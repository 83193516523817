// Navbar Styles
.navbar-section{
    @include flexRow();
    justify-content: start !important;
    padding: 0 10%;

    svg{
        width: 120px;
        height: auto;
        transition: 1s;
        margin: 0 2rem;
        cursor: pointer;

        &:hover{
            transform: scale(1.05);
        }
    }

    ul{
        @include flexRow();

        li{
            cursor: pointer;
            display: inline;
            font-weight: 500;
            line-height: 24px;
            margin: 20px;
        }
    }
}

.navbar-mobile-image{
    width: 80px;
    height: auto;
    transition: 1s;
    margin: 0 2rem;
    cursor: pointer;

    &:hover{
        transform: scale(1.05);
    }
}