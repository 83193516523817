// Cover Styles
.cover-section{
    width: 100%;

    .text-cover-section{
        @include flexColumn();
        align-items: flex-start;
        justify-content: flex-start;
        width: 55%;
        height: 85vh;
        padding: 4rem 0 0 10%;

        h1{
            margin: 1rem 0;
        }

        p{
            font-size: 16px;
        }

        #get-started-btn{
            background: linear-gradient(90deg, hsla(194, 72%, 44%, 1) 17%, hsla(219, 69%, 42%, 1) 100%);
            color: #fff;
            transition: 1s;

            &:hover{
                transform: scale(1.1);
            }
        }
    }

    .text-cover-section-mobile{
        @include flexColumn();
        width: 100%;
        height: auto;
        padding: 15% 5%;

        h1{
            margin: 1rem 0;
        }

        #get-started-btn{
            background: linear-gradient(90deg, hsla(194, 72%, 44%, 1) 17%, hsla(219, 69%, 42%, 1) 100%);
            color: #fff;
            transition: 1s;

            &:hover{
                transform: scale(1.1);
            }
        }
    }

    .image-cover-section{
        position: absolute;
        top: 0;
        right: 0;
        width: 45%;
        height: 95vh;
        object-fit: cover;
        clip-path: polygon(20% 0, 100% 0, 100% 100%, 15% 100%, 0 100%);
        background: linear-gradient(rgba(0,0,0,.0),rgba(0,.4,.4, .9)), url("../../assets/images/camino-ventura-background.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .image-cover-section-mobile{
        position: relative;
        top: auto;
        right: auto;
        width: 100%;
        height: 85vh;
        object-fit: cover;
        background: linear-gradient(rgba(0,0,0,.0),rgba(0,.4,.4, .9)), url("../../assets/images/camino-ventura-background.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }
}