// Up Button Styles
.up-button{
    background: linear-gradient(90deg, hsla(194, 72%, 44%, 1) 17%, hsla(219, 69%, 42%, 1) 100%);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    animation: heartbeat 4s infinite;
    z-index: 1000000;
    @include flexColumn();
    color: #fff;
    font-size: 2.5rem;
    cursor: pointer;

    .up-icon{
        font-size: 1.5rem;
    }
}