// Global Styles
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
}

a{
    text-decoration: none !important;
}

body , html{
    overflow-x: hidden;
    scrollbar-width: none; 
    -ms-overflow-style: none;
}
  
body::-webkit-scrollbar {
    display: none;
}

html{
    scroll-behavior: smooth;
}


// Mixins
@mixin flexColumn (){
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@mixin flexRow (){
    display: flex;
    justify-content: center;
    align-items: center;
}

// Key Frames
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 20px); }
    100%   { transform: translate(0, -0px); }   
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    14% {
        transform: scale(1.1);
        opacity: 0.75;
    }
    28% {
        transform: scale(1);
        opacity: 1;
    }
    42% {
        transform: scale(1.1);
        opacity: 0.75;
    }
    70% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

// Marquee Effect
@keyframes marquee-animation {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}