// Services Styles
.services-section{
    width: 100%;
    padding: 10% 0 0 0;
    @include flexColumn();

    h2{
        margin-bottom: 1rem;
    }

    .services{
        @include flexColumn();

        .service{ 
            margin: 5rem;
            padding: 5rem;
            @include flexRow();

            .service-content{
                @include flexColumn();

                .icon-service{
                    font-size: 3rem;
                }
            }

           .service-image{
                 width: 40%;
                 margin: 0 5%;

                 img{
                    border-radius: 30px;
                 }
           }
        }
    }
}

// Media Querys
@media screen and (max-width: 1200px) {
    .services-section{
        padding: 10% 5% 0 5%;
    
        h2{
            margin-bottom: 1rem;
        }
    
        .services{
            @include flexColumn();
            padding: 5rem;
    
            .service{ 
                margin: 2rem;
                padding: 5%;
                @include flexColumn();
    
                .service-content{
                    @include flexColumn();
    
                    .icon-service{
                        font-size: 5rem;
                    }
                }
    
               .service-image{
                     width: 100%;
                     margin: 5% 0;
                     padding: 0 5%;
                     @include flexColumn();
                     order: 1;
    
                     img{
                        max-width: 300px;
                        border-radius: 30px;
                     }
               }
            }
        }
    }
}