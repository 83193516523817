// Whatsapp Button Style 
.whatsapp-icon{
    background-color: #23D366;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    animation: heartbeat 4s infinite;
    z-index: 1000000;
    @include flexColumn();

    .telephone-icon{
        color: #fff;
        font-size: 2.5rem;
    }
}